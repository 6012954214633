:root{
    --body1: #4F4F4F;
    --body2: #9B9B9B;
    --headings-dark: #121212;
    --placeholder: #B2B2B2;
}

h1, .h1 {
    font-family: "Bebas Neue";
    color: var(--headings-dark);
    font-size: 40px;
    font-weight: 700;
}

h2, .h2 {
    font-family: "Open Sans";
    color: var(--headings-dark);
    font-size: 32px;
    font-weight: 700;
}

h3, .h3 {
    font-family: "Open Sans";
    color: var(--headings-dark);
    font-size: 24px;
    font-weight: 700;
}

h4, .h4 {
    font-family: "Open Sans";
    color: var(--headings-dark);
    font-size: 20px;
    font-weight: 700;
}

/* p defaults to body1 */
p, .body1 {
    font-family: 'Open Sans';
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--body1);
}

.body1Bold {
    font-family: 'Open Sans';
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: var(--body1);
}


.overlineBold {
    font-family: 'Open Sans';
    color: var(--headings-dark);
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    letter-spacing: 3px;
    text-transform: uppercase;
}

.body2 {
    font-family: 'Open Sans';
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: var(--body2);
}

.body2M {
    font-family: 'Open Sans';
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: var(--body2);
}

.caption {
    font-family: 'Open Sans';
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: var(--body2);
}

.captionSemi {
    font-family: 'Open Sans';
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: var(--body2);
}

.quote {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 400;
    font-size: 24px;
    line-height: 36px;
}