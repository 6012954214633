*,
*:before,
*:after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    display: flex;
    flex-direction: column;
}

body {
    font-family: "Open Sans", sans-serif;
    -webkit-font-smoothing: antialiased;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

#root {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
}